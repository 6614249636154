import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c875ccbe = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _8e5b78fc = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _7a63a8ac = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _34d59f17 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _56dd2cfa = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _ff6480dc = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0b5f4d7c = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _c64b78ca = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _b65a34a2 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _f3542a4a = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _789bbd4d = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _8efc5ed2 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _7506b14b = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _50d7ca78 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6885bec5 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _a9e0daac = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _92b3c70a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _c875ccbe,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _8e5b78fc,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _7a63a8ac,
    name: "callback"
  }, {
    path: "/downloads",
    component: _34d59f17,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _56dd2cfa,
    name: "guests-details"
  }, {
    path: "/login",
    component: _ff6480dc,
    name: "login"
  }, {
    path: "/maintenance",
    component: _0b5f4d7c,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _c64b78ca,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _b65a34a2,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _f3542a4a,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _789bbd4d,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _8efc5ed2,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _7506b14b,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _50d7ca78,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _6885bec5,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _a9e0daac,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _92b3c70a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
